import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { IModalComponent } from '../../../shared/models/modal.model';

declare var jQuery: any;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ui-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements IModalComponent{

  @ViewChild('confirmationModal') modelEl: ElementRef;

  @Input() modalTitle: string;
  @Input() lastEnclosureId: number;
  @Input() hideCancelButton = false;
  @Input() redConfirmationButton = false;

  @Output() confirm = new EventEmitter();
  @Output() cancel = new EventEmitter();

  private parameters: object;

  onConfirm() {
    this.confirm.emit(this.lastEnclosureId || this.parameters);
    this.closeModal();
  }

  openModal(parameters?) {
    this.parameters = parameters;
    jQuery(this.modelEl.nativeElement).modal('show');
  }

  closeModal() {
    (document.activeElement as HTMLElement)?.blur();
    jQuery(this.modelEl.nativeElement).modal('hide');
  }
}
